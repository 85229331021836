@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Noto+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Voltaire&display=swap");

* {
  box-sizing: border-box;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

.landingScreen * {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  z-index: 1;
}

.homepage {
  background-image: url("../../Assets/images/landing/landingBack.png");
  box-shadow: inset 0 0 0 1000px rgba(17,20,22,.55);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 53.5vw; */
  height: 100vh;
  padding: 2vw 3vw;

}

.homepage * {
  background: transparent;
  color: white;
}

.navbar {
  border-bottom: #fff 1px solid;
  display: flex;
  height: 5vw;
  justify-content: space-between;
}

.navbarLogo {
  display: flex;
}

.navbarLogo>img {
  width: 4vw;
  height: 4vw;
}

.navbarTitle {
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 0.75vw 1.5vw;
}

.navbarTitle h1 {
  font-size: 2.25vw;
  font-weight: 400;
  line-height: 2.75vw;
  margin: 0;
}

.navbarTitle p {
  font-size: 1vw;
  font-weight: 400;
  line-height: 1.1vw;
  margin: 0;
}

.navbarLinks {
  align-items: end;
  display: flex;
  justify-content: space-between;
  width: 54vw;
}

.navbarLinks p {
  cursor: pointer;
  font-size: 1.1vw;
}

.navbarLinksActive p {
  border-bottom: #5a67fd 1px solid;
  color: #5a67fd;
}

.welcomeInfo {
  width: 33vw;
}

.welcomeInfo p {
  font-size: 1.1vw;
  font-weight: 500;
  line-height: 1.4vw;
  margin: 0;
}

.welcomeInfo h1 {
  font-weight: 700;
  font-size: 3vw;
  line-height: 4.25vw;
  margin: 0.75vw 0;
}

.welcomeInfo h2 {
  font-weight: 400;
  font-size: 1.5vw;
  line-height: 2vw;
  margin: 0;
}

.loginSection {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 3vw 0 0 0;
}

.loginBox {
  width: 33vw;
  height: fit-content;
  background: rgba(105, 111, 125, 0.75);
  border-radius: 2vw;
  padding: 2vw;
  text-align: center;
}

.loginBox button {
  width: 15vw;
  padding: 1vh 0;
  margin: 1.5vh;
  font-size: 1.2vw;
  line-height: 2.7vw;
  margin: 1.8vh;
  border: none;
  cursor: pointer;

  background: linear-gradient(180deg, #5a67fd 0%, #568efc 100%);
  border-radius: 2.8vw;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0 auto;
}

.googleIconWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 2.4vw;
}

.loginBox h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 1.75vw;
  margin: 2vh;
}

.loginBox h2 {
  font-size: 1vw;
  font-weight: 300;
  margin: 2.5vh 0;
}

.loginBox h2:before,
.loginBox h2:after {
  text-decoration: line-through;
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
  margin: 1.25vh;
}

.loginBox input {
  background-color: #1e2326;
  caret-color: white;
  border: none;
  width: 22vw;
  height: 6vh;
  font-size: 1vw;
  text-align: center;
  padding: 1.25vh 3vh;
  border-radius: 0.7vw;
  margin: 1.8vh;
}

input::placeholder {
  font-size: 1vw;
}

.loginBtn button {
  background: linear-gradient(180deg, #5a67fd 0%, #568efc 100%);
  border: none;
  width: 40%;
  font-size: 1.1vw;
  font-weight: 500;
  border-radius: 0.35vw;
  padding: 1.8vh;
  margin: 2.5vh auto;
  cursor: pointer;
}

.landingPageBody * {
  text-align: center;
}

.aboutUs {
  display: flex;
  padding: 3.5vw 7vw 3.5vw 5vw;
  height: 50vh;
  justify-content: space-between;
  color: white;
}

.contentTitle {
  height: 20vw;
  width: 30vw;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.contentTitle h1 {
  font-weight: 700;
  font-size: 3vw;
  text-transform: uppercase;
  background: transparent;
}

.content {
  height: fit-content;
  width: 40vw;
  border-radius: 20px;
  background: transparent;
}

.content p {
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 2vw;
  text-align: justify;
  background: transparent;
}

.ourMission {
  display: flex;
  padding: 3.5vw 7vw 3.5vw 5vw;
  height: 50vh;
  justify-content: space-between;
  color: white;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20vw;
}

.tenders {
  padding: 4vw 5vw;
  color: white;
}

.tenders h1 {
  font-weight: 700;
  font-size: 3vw;
  margin: 2vw 0 5vw 0;
  text-transform: uppercase;
  position: relative;
  background: transparent;
}

.tenderContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 3vw;
}

.tenderCard {
  width: 43vw;
  height: 25vw;
  background: #1e2326;
  border-radius: 10px;
  padding: 2vw;
  display: flex;
  gap: 2vw;
}

.tenderContent {
  background-color: #1e2326;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tenderHeader {
  background-color: #1e2326;
}

.tenderHeader h1 {
  background-color: transparent;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25vw;
  margin: 0 0 0.5vw;
  text-align: left;
  color: #d9d9d9;
  display: flex;

}

.tenderHeader p {
  background-color: transparent;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;
  margin: 0 0 1vw;
  text-align: left;
  color: #d9d9d9;
}

.btns {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 0.1vw;
}

.viewBtn {
  background: transparent;
  border-radius: 0.35vw;
  border: 2px solid #5a67fd;
  color: #5a67fd;
  cursor: pointer;
  font-size: 1.1vw;
  font-weight: 500;
  height: 2.5vw;
  width: 8vw;
}

.applyBtn {
  background: linear-gradient(180deg, #5a67fd 0%, #568efc 100%);
  border-radius: 0.35vw;
  border: none;
  cursor: pointer;
  font-size: 1.1vw;
  font-weight: 500;
  height: 2.5vw;
  width: 8vw;
}

.suInitiatives {
  padding: 4vw 5vw;
  color: white;
}

.suInitiatives h1 {
  font-weight: 700;
  font-size: 3vw;
  margin: 1vw 0 2vw 0;
  text-transform: uppercase;
  position: relative;
  background: transparent;
}

.suInitiatives h2 {
  width: 50vw;
  margin: 1vw auto;
  font-weight: 700;
  font-size: 1.75vw;
}

.suContainer {
  display: flex;
  justify-content: space-between;
  padding: 2vw;
  background: transparent;
}

.suCard {
  width: 23vw;
  border-radius: 10px;
  background: transparent;
}

.suCardTitle {
  display: flex;
  background: transparent;
}

.suCardTitle h1 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-size: 5vw;
  margin: 0 0 1vw 0;

  background: linear-gradient(95.58deg, #d55c56 5.99%, #9832c6 102.73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.suCardTitle h2 {
  width: auto;
  margin: auto;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  background: transparent;
  text-transform: uppercase;
}

.suCardImg {
  height: 15vw;
  width: 23vw;
  border-radius: 10px;
  background: #1e2326;
  object-fit: cover;
}

.suCardContent {
  /* height: 13.2vw; */
  border-radius: 10px;
  background: rgba(105, 111, 125, 0.65);
  padding: 0.75vw;
}

.suCardContent p {
  margin: 0;
  background: transparent;
  color: #cfcfcf;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1.25vw;
  /* text-align: justify; */
  user-select: none;
}

.team {
  padding: 4vw 5vw;
  color: white;
}

.team h1 {
  font-weight: 700;
  font-size: 3vw;
  margin: 1vw 0 2vw 0;
  text-transform: uppercase;
}

.teamContainer {
  display: flex;
  justify-content: space-evenly;
  padding: 1.5vw 2.5vw;
}

.teamCard {
  width: 24vw;
  height: 31vw;
  background: rgba(105, 111, 125, 0.65);
  border-radius: 10px;
  padding: 2vw;
}

.teamCardImg {
  width: 13vw;
  object-fit: cover;
  height: 13vw;
  border-radius: 100%;
  background-color: rgb(212, 205, 203);
}

.teamCardTitle {
  background: transparent;
}

.teamCardTitle h2 {
  background: transparent;
  margin: 1vw auto 0.5vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
}

.teamCardTitle p {
  background: transparent;
  margin: 0;
  margin: 1vw auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25vw;
}

.teamCardTitle a {
  background: transparent;
  padding: 1vw;
}

.devs {
  padding: 4vw 5vw 6vw;
  color: white;
}

.devs h1 {
  font-weight: 700;
  font-size: 3vw;
  margin: 1vw 0 2vw 0;
  text-transform: uppercase;
}

.devsNavbar {
  height: 6vw;
  width: 35vw;
  margin: 1vw auto;
  display: flex;
  justify-content: space-between;
}

.devsNavbar p {
  font-family: "Voltaire", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 4vw;
  margin: auto 0;
  cursor: pointer;
}

.devsCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2vw;
  padding: 1vw 10vw;
}

.devsCardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2vw;
  /* padding: 1vw 10vw; */
}

.devsCard {
  width: 22vw;
  height: 18vw;

  background: rgba(105, 111, 125, 0.65);
  border-radius: 10px;
}

.footer {
  margin: 0 5vw;
  padding: 1.75vw 0 0;
  border-top: #fff 1px solid;
  color: white;
}

.footer h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  font-size: 2vw;
  line-height: 3vw;
  margin: 0;
  text-transform: uppercase;
}

.footer p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 3vw;
  margin: 0;
}

.footerLinks {
  width: 50vw;
  margin: auto;
  padding: 1vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerLinks p {
  cursor: pointer;
}

.footerLogoLink {
  width: 24vw;
  margin: 1vw auto 2vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerLove {
  border-top: #fff 1px solid;
}

.footerLove {
  margin: 0.5vw;
}

.backdrop {
  position: absolute;
  width: auto;
  height: auto;
  z-index: -1;
}

.backdrop1 {
  position: absolute;
  top: 100vh;
  background: transparent;
}


.backdrop2 {
  position: absolute;
  top: 150vh;
  right: 0;
}

.backdrop3 {
  position: absolute;
  top: calc(200vh + 30vw);
}

.backdrop4 {
  position: absolute;
  right: 0;
  top: calc(200vh + 20vw);
}


.backdrop5 {
  position: absolute;
  top: calc(200vh + 30vw);
}

.carousel .slide {
  background: transparent;
}

@media (max-width: 480px) {
  .navbar {
    height: 5vh;
    margin-bottom: 5vh;
  }

  .navbarLogo {
    display: flex;
  }

  .navbarLogo>img {
    height: 4vh;
    width: 4vh;
  }

  .navbarTitle {
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 1.5vw 3vw;
  }

  .navbarTitle h1 {
    font-size: 4.5vw;
    line-height: 5.5vw;
  }

  .navbarTitle p {
    font-size: 2vw;
    line-height: 2.2vw;
  }

  .navbarLinks {
    display: none;
  }

  .loginSection {
    flex-direction: column;
    height: 80vh;
  }

  .welcomeInfo {
    margin: 0 auto;
    width: 66vw;
  }

  .loginBox {
    margin: 0 auto;
    width: 66vw;
    height: 85vw;
    border-radius: 4vw;
    padding: 4vw;
  }

  .loginBox button {
    width: 37.5vw;
    padding: 1.875vw 0;
    margin: 2.5vw;
    font-size: 3vw;
    line-height: 6.75vw;
    margin: 2.5vw;
    border: none;
    cursor: pointer;

    background: linear-gradient(180deg, #5a67fd 0%, #568efc 100%);
    border-radius: 7vw;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 auto;
  }

  .googleIconWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 6vw;
  }

  .welcomeInfo p {
    font-size: 2.75vw;
    line-height: 3.5vw;
  }

  .welcomeInfo h1 {
    font-size: 7.5vw;
    line-height: 10.625vw;
    margin: 1.5vw 0;
  }

  .welcomeInfo h2 {
    font-size: 3.75vw;
    line-height: 5vw;
  }

  .loginBox h1 {
    font-size: 4.375vw;
    margin: 2.5vw;
  }

  .loginBox h2 {
    font-size: 2.5vw;
    margin: 3vw 0;
  }

  .loginBox h2:before,
  .loginBox h2:after {
    margin: 1.5vw;
  }

  .loginBox input {
    width: 44vw;
    height: 6.5vw;
    font-size: 2.5vw;
    padding: 1.5vw 3.5vw;
    border-radius: 1.4vw;
    margin: 2vw;
  }

  input::placeholder {
    font-size: 2.5vw;
  }

  .loginBox button {
    border-radius: 2.8vw;
  }

  .loginBtn button {
    width: 80%;
    font-size: 2.75vw;
    border-radius: 0.7vw;
    padding: 2vw;
    margin: 3vw auto;
    cursor: pointer;
  }

  .aboutUs {
    padding: 0;
    flex-direction: column;
    justify-content: space-around;
  }

  .contentTitle {
    width: 90vw;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .contentTitle h1 {
    font-size: 7.5vw;
    text-transform: uppercase;
  }

  .content {
    width: 75vw;
    border-radius: 20px;
    margin: 0 auto;
  }

  .content p {
    font-size: 3.75vw;
    line-height: 5vw;
  }

  .ourMission {
    padding: 0;
    flex-direction: column;
    justify-content: space-around;
  }

  .backdrop1 {
    display: none;
  }

  .backdrop2 {
    display: none;
  }
}