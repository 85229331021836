@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.homeContainer {
  width: 55vw;
  color: white;
  padding: 0 2vw 0 3vw;
}

.title h1 {
  margin: 0.5vw 0 0 0;
  font-size: 2.5vw;
  line-height: 3.6vw;
}

.title h2 {
  margin: 0.5vw 0 0;
  font-size: 1vw;
  color: rgba(255, 255, 255, 0.4);
}

.contentBox {
  margin: 2vw 0;
  height: 78vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.contentBox::-webkit-scrollbar {
  display: none;
}

.contentBox h1 {
  color: linear-gradient(180deg, #c5caff 0%, #eef4ff 100%);
  margin: 0 0 1.5vw 0;
}

.header {
  display: flex;
  justify-content: space-between;
}

.toggleSwitch {
  display: flex;
  margin: 0 0 1.25vw 0;
  align-items: center;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 92vh;
  width: 55vw;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  row-gap: 2vw;
  column-gap: 2vw;
}

.card {
  background: #1e2326;
  width: 23.5vw;
  border-radius: 15px;
  padding: 0;

  display: flex;
  flex-direction: row;
  justify-content: start;
  column-gap: 1.5vw;
}

.card img {
  width: 8.82vw;
  height: 13.26vw;
}

.cardDetails {
  background: #1e2326;
}

.cardText {
  background: #1e2326;
  padding: 1vw 0;
}

.cardText h3,
.cardText h4,
.cardText p {
  background: transparent;
  margin: 0;
}

.cardText h3 {
  color: #c5caff;
  font-size: 1.2vw;
  margin: 0.25vw 0;
}

.cardText h4 {
  color: #ababab;
  font-size: 0.9vw;
  margin: 0 0 0.75vw 0;
}

.cardText p {
  font-size: 1vw;
  margin: 0.4vw 0;
}

.cardStats {
  background: linear-gradient(180deg, #5a67fd 0%, #568efc 100%);
  width: 8vw;
  height: 2vw;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 4vw;
  margin-top: 1vw;
}

.cardStats:hover {
  cursor: pointer;
}

.cardStats p {
  background: transparent;
  font-size: 1vw;
  margin: 0;
}