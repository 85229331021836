@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.homeContainer {
  width: 55vw;
  color: white;
  padding: 0 2vw 0 3vw;
}

.title h1 {
  margin: 0.5vw 0 0 0;
  font-size: 2.5vw;
  line-height: 3.6vw;
}

.title h2 {
  margin: 0.5vw 0 0;
  font-size: 1vw;
  color: rgba(255, 255, 255, 0.4);
}

.contentBox {
  margin: 2vw 0;
}

.contentBox h1 {
  color: linear-gradient(180deg, #c5caff 0%, #eef4ff 100%);
  margin-top: 0;
}

.cards {
  display: flex;
  justify-content: start;
}

.card {
  background: #1e2326;
  width: 22vw;
  border-radius: 15px;
  padding: 0.5vw 0 0 0;
  margin: 1vw 2.5vw 0 0;
}

.card:hover {
  cursor: pointer;
}

.cardImage {
  background: transparent;
  margin: auto;
  width: 16vw;
  height: 10vw;
}

.cardText {
  background: #1e2326;
  display: flex;
  justify-content: space-between;
  margin: 1vw 1.5vw;
}

.cardText h3 {
  background: transparent;
  margin: 0.5vw 0;
  font-size: 1.4vw;
}
