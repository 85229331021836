@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.homeContainer {
  width: 55vw;
  color: white;
  padding: 0 2vw 0 3vw;
}

.title h1 {
  margin: 0.5vw 0 0 0;
  font-size: 2.5vw;
  line-height: 3.6vw;
}

.title h2 {
  margin: 0.5vw 0 0;
  font-size: 1vw;
  color: rgba(255, 255, 255, 0.4);
}

.contentBox {
  margin: 2vw 0;
  height: 78vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.contentBox::-webkit-scrollbar {
  display: none;
}

.contentBox h1 {
  color: linear-gradient(180deg, #c5caff 0%, #eef4ff 100%);
  margin-top: 0;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 92vh;
  width: 55vw;
}

.contentCard {
  background: #1e2326;
  padding: 2vw;
  border-radius: 20px;
  margin: 0 0 2.5vw 0;
}

.contentCard p {
  background: transparent;
  margin: 0 0 1vw 0;
  text-align: justify;
}

.contentCard img {
  float: left;
  margin: 0 2vw 2vw 0;
  border-radius: 10px;
}

.contentCardTitle {
  background: #1e2326;
  display: flex;
  justify-content: space-between;
}

.contentCardTitle h3 {
  background: transparent;
  margin: 0 0 2vw 0;
}

.contentCardTitle p {
  background: transparent;
  margin: 0 0 1vw 0;
  text-align: justify;
}
