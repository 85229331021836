@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  height: auto;
}

.content {
  width: 25vw;
  height: auto;
  background: #111416;
  color: white;
  padding: 0 1.5vw 0 2.5vw;
}

.heading {
  display: flex;
}

.headingText h1 {
  padding: 0;
  margin: 0;
  font-size: 2vw;
}

.headingText h3 {
  padding: 0;
  margin: 0;
  font-size: 1.1vw;
}

.headingText {
  margin: 0 0 0 1.25vw;
}

.panelContents h4 {
  margin: 1.5vw 0 0 1.5vw;
  font-size: 1.1vw;
  color: grey;
}

.panelTab {
  display: flex;
  margin: 0.75vw 0;
  /* height: 2.75vw; */
  height: 3vw;
  width: 20vw;
  padding: 0 0 0 1.5vw;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.3s;
}

.panelTab:hover {
  background-color: #6970c651;
}

.panelTab p {
  margin: 0 1vw;
  line-height: 1.5vw;
  font-size: 1.2vw;
  background-color: #ffffff00;
}

.panelTabActive {
  display: flex;
  background: linear-gradient(180deg, #5a67fd 0%, #568efc 100%);
  margin: 0.75vw 0;
  height: 2.75vw;
  width: 20vw;
  padding: 0 0 0 1.5vw;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.panelTabActive p {
  background-color: #ffffff00;
  margin: 0 1vw;
  line-height: 1.5vw;
  font-size: 1.2vw;
}

.panelContents {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

.footer {
  position: absolute;
  display: flex;
  width: 20vw;
  margin: 9vw 0 0 0;
  bottom: 25px;
}

.footerText {
  margin: 0 1vw;
}

.footerText h3 {
  margin: 0.25vw 0;
  font-size: 1.25vw;
}

.footerText h4 {
  margin: 0;
  font-size: 1vw;
}

@media screen and (min-width: 1285px) {
  .panelTab {
    display: flex;
    margin: 0.75vw 0;
    height: 2.75vw;
    /* height: 2.1vw; */
    width: 20vw;
    padding: 0 0 0 1.5vw;
    border-radius: 5px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
  }

  .panelTab p {
    background-color: #ffffff00;
    margin: 0 1vw;
    line-height: 1.15vw;
    font-size: 1.1vw;
  }

  .panelTabActive {
    display: flex;
    background: linear-gradient(180deg, #5a67fd 0%, #568efc 100%);
    margin: 1vw 0;
    height: 2.75vw;
    width: 20vw;
    padding: 0 0 0 1.5vw;
    border-radius: 5px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  .panelTabActive p {
    background-color: #ffffff00;
    margin: 0 1vw;
    line-height: 1.15vw;
    font-size: 1.1vw;
  }

  .footer {
    position: absolute;
    display: flex;
    width: 20vw;
    margin: 10vw 0 0 0;
    bottom: 25px;
  }

  .footerText {
    margin: 0 0.9vw;
  }

  .footerText h3 {
    margin: 0.25vw 0;
    font-size: 1.2vw;
  }

  .footerText h4 {
    margin: 0;
    font-size: 0.8vw;
  }
}