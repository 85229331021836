@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  height: auto;
}

.content {
  width: 25vw;
  height: auto;
  background: #111416;
  color: white;
  padding: 0 1.5vw 0 2.5vw;
}

.heading {
  display: flex;
  padding-top: 2vh;
}

.headingText h1 {
  padding: 0;
  margin: 0;
  font-size: 2vw;
}

.headingText h3 {
  padding: 0;
  margin: 0;
  font-size: 1.1vw;
}

.headingText {
  margin: 0 0 0 1.25vw;
}

.panelContents {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

.footer {
  position: absolute;
  display: flex;
  width: 20vw;
  margin: 9vw 0 0 0;
  bottom: 25px;
}

.footerText {
  margin: 0 1vw;
}

.footerText h3 {
  margin: 0.25vw 0;
  font-size: 1.25vw;
}

.footerText h4 {
  margin: 0;
  font-size: 1vw;
  color: grey;
}

@media screen and (min-width: 1285px) {
  .footer {
    position: absolute;
    display: flex;
    width: 20vw;
    margin: 10vw 0 0 0;
    bottom: 25px;
  }

  .footerText {
    margin: 0 0.9vw;
  }

  .footerText h3 {
    margin: 0.25vw 0;
    font-size: 1.2vw;
  }

  .footerText h4 {
    font-size: 0.8vw;
  }
}