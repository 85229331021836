@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.homeContainer {
  width: 55vw;
  color: white;
  padding: 0 2vw 0 3vw;
}

.title h1 {
  margin: 0.5vw 0 0 0;
  font-size: 2.5vw;
  line-height: 3.6vw;
}

.title h2 {
  margin: 0.5vw 0 0;
  font-size: 1vw;
  color: rgba(255, 255, 255, 0.4);
}

.contentBox {
  margin: 2vw 0;
  height: 78vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.contentBox::-webkit-scrollbar {
  display: none;
}

.contentBox h1 {
  color: linear-gradient(180deg, #c5caff 0%, #eef4ff 100%);
  margin-top: 0;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 92vh;
  width: 55vw;
}

.cards {}

.card {
  border-radius: 10px;
  padding: 1.5vw;
  background: #1e2326;
  margin: 0 0 1.5vw 0;
}

.card>p {
  background: transparent;
  height: 8vw;
  overflow-y: hidden;
}

.cardHeading {
  display: flex;
  background: #1e2326;
  justify-content: space-between;
}

.cardHeading h3,
.cardHeading p {
  margin: 0;
  background: transparent;
}

.cardFooter {
  display: flex;
  background: #1e2326;
  justify-content: space-between;
}

.cardFooter p {
  background: transparent;
  color: #313bae;
}