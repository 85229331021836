.main {
    display: flex;
    /* padding: 6vh 0 0 0; */
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
}

.center {
    border-right: 0.5px solid rgb(64, 64, 64);
    border-left: 0.5px solid rgb(64, 64, 64);
    height: 100%;
    width: 55vw;
}
