@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.homeContainer {
  width: 55vw;
  color: white;
  padding: 0 2vw 0 3vw;
}

.title h1 {
  margin: 0.5vw 0 0 0;
  font-size: 2.5vw;
  line-height: 3.6vw;
}

.title h2 {
  margin: 0.5vw 0 0;
  font-size: 1vw;
  color: rgba(255, 255, 255, 0.4);
}

.contentBox {
  margin: 2vw 0;
  height: 78vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.contentBox::-webkit-scrollbar {
  display: none;
}

.contentBox h1 {
  color: linear-gradient(180deg, #c5caff 0%, #eef4ff 100%);
  margin-top: 0;
}

.formContainer {
  margin: 2vw 0 0 0;
}

.form {
  display: flex;
  justify-content: space-between;
}

.formImage {
  width: 22vw;
  height: 23vw;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vw;
}

.formImage img {
  width: 100%;
  max-height: 24vw;
}

.uploadLabel {
  color: #5a67fd;
  border: 2px solid #5a67fd;
  width: 8vw;
  font-size: 1vw;
  text-align: center;
  vertical-align: middle;
  padding: 0.5vw;
  cursor: pointer;
}

.imgInput {
  display: none;
}

.formDetails {
  margin: 0 2vw 0 0;
  width: 24vw;
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
}

.formDetails>input {
  background-color: #1e2326;
  caret-color: #ffffff;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 1vw;
  color-scheme: dark;
}

.formDetails>textarea {
  background-color: #1e2326;
  caret-color: #ffffff;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: white;
  padding-left: 14px;
  padding-top: 8px;
  border: none;
  border-radius: 8px;
  resize: none;
}

::placeholder {
  font-size: small;
  background: linear-gradient(180deg, #c5caff 0%, #eef4ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  opacity: 0.8;
}

.formDetails>textarea:focus {
  outline: none;
}

.formDetails>select {
  border: none;
  background-color: #1e2326;
  padding: 1vw;
  border-radius: 8px;
}

.formDetails>select:focus {
  outline: none;
}

.formOptions {
  padding: 0 2vw 0 0;
  display: flex;
  justify-content: end;
}

.formOptions>input {
  background-color: #1e2326;
}

.formOptionsCustomName {
  display: flex;
  column-gap: 1vw;
}

.formOptionsCustomName>p {
  background: linear-gradient(180deg, #c5caff 0%, #eef4ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  opacity: 0.9;
}

.formOptionsCancellable {
  display: flex;
  column-gap: 1vw;
}

.formOptionsCancellable>p {
  background: linear-gradient(180deg, #c5caff 0%, #eef4ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  opacity: 0.9;
}

.buttons {
  padding: 0 2vw 0 0;
  display: flex;
  justify-content: space-between;
}

.buttons button {
  color: white;
}

.buttons>.back {
  color: #5a67fd;
  border: 2px solid #5a67fd;
  border-radius: 5px;
  width: 6vw;
  font-size: 1vw;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  padding: 8px;
}

.buttons>.back:hover {
  cursor: pointer;
}

.buttons>.submit {
  background: linear-gradient(180deg, #5a67fd 0%, #568efc 100%);
  border: none;
  border-radius: 4px;
  color: white;
  width: 8vw;
  font-size: 1vw;
  font-weight: 600;
  padding: 10px;
  font-family: "Poppins", sans-serif;
}

.buttons>.submit:hover {
  cursor: pointer;
}

.imgCarouselDiv {
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
}

.imgCarouselDiv>img {
  height: 100%;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}