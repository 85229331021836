@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.container {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.content {
  width: 20vw;
  background: #111416;
  color: white;
  margin: 1vw 0;
  padding: auto;
  text-align: center;
}

.header h1 {
  margin: 0.5vw 0;
  background: top;
  font-size: 2vw;
}

.footer {
  position: absolute;
  margin: 24vw 2vw 0;
  width: 16vw;
  height: 2.75vw;
  border-radius: 0.75vw;
  background: radial-gradient(99.85% 99.85% at 0% 0%,
      #8dff8f 0%,
      #f28a8a 0.01%,
      #eb5757 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 25px;
}

.footer p {
  margin: 0;
  background-color: transparent;
  font-size: 1.25vw;
}

.footer:hover {
  cursor: pointer;
}