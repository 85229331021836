@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.homeContainer {
  width: 55vw;
  color: white;
  padding: 0 2vw 0 3vw;
}

.title h1 {
  margin: 0.5vw 0 0 0;
  font-size: 2.5vw;
  line-height: 3.6vw;
}

.title h2 {
  margin: 0.5vw 0 0;
  font-size: 1vw;
  color: rgba(255, 255, 255, 0.4);
}

.contentBox {
  margin: 2vw 0;
  height: 78vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.contentBox::-webkit-scrollbar {
  display: none;
}

.contentBox h1 {
  color: linear-gradient(180deg, #c5caff 0%, #eef4ff 100%);
  margin-top: 0;
}

.header {
  margin: 0 0 2vw 0;
}

.header h1 {
  font-size: 1.5vw;
  margin: 0 0 2.5vw 0;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.standardButton {
  display: flex;
}

.standardButton button {
  color: #5a67fd;
  border: 2px solid #5a67fd;
  border-radius: 5px;
  width: 10vw;
  padding: 0.35vw 1vw;
  font-size: 1vw;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  margin: 0 2vw 0 0;
}

.removeButton button {
  background: radial-gradient(99.85% 99.85% at 0% 0%,
      #8dff8f 0%,
      #f28a8a 0.01%,
      #eb5757 100%);
  border-radius: 5px;
  width: 10vw;
  padding: 0.35vw 1vw;
  font-size: 1vw;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
}