.privacy {
    width: 75vw;
    /* height: 100vh; */
    padding: 2vh 2.5vw;
    margin: 0 auto;
    background-color: #1e2326;
    color: white;
    border-left: 0.5px solid rgb(64, 64, 64);
    border-right: 0.5px solid rgb(64, 64, 64);
}

.privacy > h1 {
    background-color: inherit;
    border-bottom: 0.5px solid rgb(64, 64, 64);
    padding: 2vh 0;
}

.privacy > h2 {
    background-color: inherit;
    padding: 2vh 0;
}

.privacy > ul {
    background-color: inherit;
}

.privacy > ul > li {
    background-color: inherit;
    padding: 0.25vh 0;
}

.privacy > ul > li > a {
    background-color: inherit;
    color: #4285F4;
    text-decoration: none;
}

.privacy > p {
    background-color: inherit;
    margin: 2vh 0;
}

.button {
    width: fit-content;
    background: linear-gradient(180deg, #5a67fd 0%, #568efc 100%);
    padding: 1.5vh 2vw;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    margin-top: 4vh;
    margin-bottom: 2vh;
}

@media (max-width: 480px) {
    .privacy {
        width: 100vw;
        padding: 10vw;
    }

    .privacy > h1 {
        text-align: center;
    }
    
    .privacy > h2 {
        text-align: center;
    }
}
