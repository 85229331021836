* {
  box-sizing: border-box;
  background-color: #111416;
}

.mainContainer {
  display: flex;
  padding: 6vh 0 0 0;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  /* overflow-y: hidden; */
}

.centerPanel {
  border-right: 0.5px solid rgb(64, 64, 64);
  border-left: 0.5px solid rgb(64, 64, 64);
  height: 100%;
}

.loader-back {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 400ms linear;
}

.loader-logo {
  width: 30vh;
  height: auto;
}