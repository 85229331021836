@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend&display=swap");

.homeContainer {
  width: 55vw;
  color: white;
  padding: 0 2vw 0 3vw;
}

.title {
  background-color: #00000000;
}

.title h1 {
  margin: 0.5vw 0 0 0;
  font-size: 2.5vw;
  line-height: 3.6vw;
  background-color: #00000000;
}

.title h2 {
  margin: 0.5vw 0 0;
  font-size: 1vw;
  color: rgba(255, 255, 255, 0.4);
}

.contentBox {
  margin: 2vw 0;
  height: 78vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.contentBox::-webkit-scrollbar {
  display: none;
}

.contentBox h1 {
  color: linear-gradient(180deg, #c5caff 0%, #eef4ff 100%);
  margin-top: 0;
}

.radio {
  display: flex;
  gap: 8px;
  background: #22272A;
  width: min-content;
  border-radius: 8px;
  border: 4px solid #22272A;
}

.radioButton {
  background: #22272A;
  border: none;
  border-radius: 4px;
  color: white;
  width: 8vw;
  font-size: 0.75vw;
  font-weight: 600;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.radioButtonSelected {
  background: linear-gradient(180deg, #5a67fd 0%, #568efc 100%);
  border: none;
  border-radius: 4px;
  color: white;
  width: 8vw;
  font-size: 0.75vw;
  font-weight: 600;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.formContainer {
  margin: 2vw 0 0 0;
}

.form {
  display: flex;
  justify-content: space-between;
}

.formImage {
  width: 22vw;
  height: 23vw;
  border-radius: 10px;
  /* background: #1e2326; */
  display: flex;
  flex-direction: column;
  gap: 3vw;
  align-items: center;
}

/* .formImage>img {
  width: 100%;
  height: auto;
} */

.uploadLabel {
  color: #5a67fd;
  border: 2px solid #5a67fd;
  width: 8vw;
  font-size: 1vw;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  padding: 6px;
}

.uploadLabel:hover {
  cursor: pointer;
}

.inputWrapper {
  background-color: #ffffff00;
}

.imgInput {
  display: none;
}

.formDetails {
  margin: 0 2vw 0 0;
  width: 24vw;
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
}

.formDetails>input {
  background-color: #1e2326;
  caret-color: #ffffff;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 1vw;
}

.formDetails>input:focus {
  outline: none;
}

.formDetails>textarea {
  /* margin-top: 2vw; */
  /* height: 12vw; */
  height: 100%;
  background-color: #1e2326;
  caret-color: #ffffff;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: white;
  padding-left: 14px;
  padding-top: 8px;
  border: none;
  border-radius: 8px;
  resize: none;
}

::placeholder {
  font-size: small;
  background: linear-gradient(180deg, #c5caff 0%, #eef4ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  opacity: 0.8;
}

.formDetails>textarea:focus {
  outline: none;
}

.formDetails>select {
  border: none;
  background-color: #1e2326;
  padding: 1vw;
  border-radius: 8px;
}

.formDetails>select:focus {
  outline: none;
}

.formOptions {
  display: flex;
  justify-content: space-between;
}

.formOptions>input {
  background-color: #1e2326;
}

.formOptionsCustomName {
  display: flex;
  column-gap: 1vw;
}

.formOptionsCustomName>p {
  background: linear-gradient(180deg, #c5caff 0%, #eef4ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  opacity: 0.9;
}

.formOptionsCancellable {
  display: flex;
  column-gap: 1vw;
}

.formOptionsCancellable>p {
  background: linear-gradient(180deg, #c5caff 0%, #eef4ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  opacity: 0.9;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2vw;
}

.buttons button {
  color: white;
}

.buttons>.back {
  color: #5a67fd;
  border: 2px solid #5a67fd;
  border-radius: 5px;
  width: 6vw;
  font-size: 1vw;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  padding: 8px;
}

.buttons>.back:hover {
  cursor: pointer;
}

.buttons>.submit {
  background: linear-gradient(180deg, #5a67fd 0%, #568efc 100%);
  border: none;
  border-radius: 4px;
  color: white;
  width: 8vw;
  font-size: 1vw;
  font-weight: 600;
  padding: 10px;
  font-family: "Poppins", sans-serif;
}

.buttons>.submit:hover {
  cursor: pointer;
}

.imgCarouselDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17vw;
  width: fit-content;
  margin: 0 auto;
}

.imgCarouselDiv > img {
  height: 17vw;
}

.comboDetails {
  margin: 0 2vw 0 0;
  width: 24vw;
  display: flex;
  flex-direction: column;
}

.merchCombo {
  flex-grow: 1;
  background-color: #1E2326;
  border-radius: 8px;
  padding: 16px 20px;
}

.merchComboHeading {
  background-color: #1E2326;
  font-family: 'Poppins';
  font-size: 18px;
  line-height: 24px;
  opacity: 0.6;
  padding-bottom: 1vw;
}

.merchItem {
  display: flex;
  justify-content: space-between;

  background-color: #1E2326;
  padding: 19px 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  border-top: 1px solid #FFFFFF0D;
}

.merchItemIcons {
  display: flex;
  justify-content: space-between;
  gap: 1.5vw;

  background-color: #1E2326;
}

.merchItemIcons > img {
  background-color: #1E2326;
  cursor: pointer;
}

.merchComboButton {
  background: linear-gradient(180deg, #5a67fd 0%, #568efc 100%);
  border: none;
  border-radius: 4px;
  color: white;
  width: 8vw;
  font-size: 0.7vw;
  font-weight: 600;
  padding: 5px 10px;
  font-family: "Poppins", sans-serif;
  opacity: 0.6;
  cursor: pointer;
  transition: all 0.4s;
  margin-top: 0.5vw;
  display: flex;
  justify-content: space-between;
}

.merchComboButton:hover {
  opacity: 1;
}

.modal {
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #111416;
    padding: 2vw 5vw;
    border-radius: 8px;
    width: 32vw;
}

.modalTitle {
  margin-bottom: 2vw;
  font-size: 2.5vw;
  font-weight: 600;
  line-height: 3.6vw;
  background-color: #00000000;
  font-family: 'Lexend';
  background-image: linear-gradient(180deg, #C5CAFF 0%, #EEF4FF 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  display: flex;
  justify-content: space-between;
}

.modalTitle > img {
  cursor: pointer;
}

.modalDetails {
  margin: 0 2vw 0 0;
  width: 22vw;
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
}

.modalDetails > input {
  background-color: #1e2326;
  caret-color: #ffffff;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 1vw;
  margin-top: 1vw;
}

.modalDetails > select {
  border: none;
  background-color: #1e2326;
  padding: 1vw;
  border-radius: 8px;
  margin-top: 1vw;
}

.modalButton {
  background: linear-gradient(180deg, #5a67fd 0%, #568efc 100%);
  border: none;
  border-radius: 5px;
  color: white;
  width: 7vw;
  height: 2.66vw;
  font-size: 1.3vw;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  transition: all 0.4s;
  margin: 3vw 0 4vw 0;
}
