@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.homeContainer {
  width: 55vw;
  color: white;
  padding: 0 2vw 0 3vw;
}

.title h1 {
  margin: 0.5vw 0 0 0;
  font-size: 2.5vw;
  line-height: 3.6vw;
}

.title h2 {
  margin: 0.5vw 0 0;
  font-size: 1vw;
  color: rgba(255, 255, 255, 0.4);
}

.contentBox {
  margin: 2vw 0;
}

.contentBox h1 {
  color: linear-gradient(180deg, #c5caff 0%, #eef4ff 100%);
  margin-top: 0;
}

.formContent {
  margin: 0 4vw 0 0;
  width: 45vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.dateWrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 2vw;
}

.dateWrapper>p {
  font-family: "Poppins";
  font-size: 14px;
  margin: auto 2vw;
  color: #ffffff99;
}

.dateWrapper>input {
  background-color: #1e2326;
  caret-color: #ffffff;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 1vw;
  color-scheme: dark;
}

#tenderHeading {
  background-color: #1e2326;
  height: 3.5vw;
  caret-color: #ffffff;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: white;
  padding-left: 14px;
  border: none;
  border-radius: 8px;
}

.formContent input:focus {
  outline: none;
}

.formContent textarea {
  margin-top: 2vw;
  height: 12vw;
  background-color: #1e2326;
  caret-color: #ffffff;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: white;
  padding-left: 14px;
  padding-top: 8px;
  border: none;
  border-radius: 8px;
  resize: none;
}

.formContent textarea:focus {
  outline: none;
}

.buttons {
  margin: 3vw 0;
  display: flex;
  justify-content: space-between;
}

.buttons button {
  height: 3vw;
}

.addPDF {
  color: #5a67fd;
  border: 2px solid #5a67fd;
  border-radius: 5px;
  width: 8vw;
  padding: 0.6vw 0 0 0;
  font-size: 1vw;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  padding-top: 0.6vw;
}

.addPDF:hover {
  cursor: pointer;
}

.post {
  background: linear-gradient(180deg, #5a67fd 0%, #568efc 100%);
  border: none;
  border-radius: 4px;
  color: white;
  width: 6vw;
  font-size: 1vw;
  font-weight: 500;
}

.post:hover {
  cursor: pointer;
}

.pdfInput {
  display: none;
}

.confirm {
  color: #5a68fdb0;
}